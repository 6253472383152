<template>
<div class="im">
    <img src="~assets/images/index/im.png" alt="" class="img">
    <div class="text">私信请在酸谈小程序中使用</div>
    <div class="text"><span class="text-time">{{num}}</span> 秒后将返回到上一页</div>
</div>

</template>

<script>
export default {
  name: "user.im",
  data(){
    return{
      time:3,
      num:3
    }
  },

    created() {
         this.timer = setInterval(()=>{
      if(this.time == 0){
        clearInterval(this.timer);
       this.$router.go(-1)
      }
      this.time--;
      this.num = this.time;
    },1000)
  },
  methods:{

  },
  components:{
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.im{
  text-align: center;
  padding-top: 200/$r;
}
.img{
  width: 406/$r;
  height: 211/$r;
  margin: 0 auto;
}
.text{
  font-size: 26/$r;
font-family: PingFang SC;
font-weight: 500;
color: #999999;
}
</style>